import React, { Component } from 'react';
import NewsItem from './news/NewsItem';

import axios from 'axios';

import { apiUrl } from '../Config';
import '../css/kaigienews.css';
// will be used for single expert or all experts
class CommunityNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsItems: [],
      newsType: 'community',
      expert: [],
      expertArtistId: 0,
      newsPopulated: false
    };
  }
  componentDidMount() {
    //const { newsType } = this.props.match.params;
    this.setState({ newsType: 'community' });
    this.getCommunityPosts();
  }

  async getCommunityPosts() {
    const postsUrl = apiUrl + '/communitynewspost';

    const res = await axios.get(postsUrl).catch(err => console.log(err));
    console.log(res);
    this.setState({
      newsPosts: res.data,
      newsPopulated: true
    });
  }
  createMarkup = description => {
    return { __html: description };
  };
  render() {
    return (
      <div className="container">
        <div className="row  justify-content-center mb-5 page-header rounded">
          <h4
            className="p-2"
            style={{ alignSelf: 'center', textAlign: 'center' }}
          >
            Community News Posts
          </h4>
        </div>
        <div className="row justify-content-center">
          {this.state.newsPopulated === false && (
            <h5 style={{ color: 'green' }}>Please Wait - Getting News</h5>
          )}
          {this.state.newsPopulated === true &&
            this.state.newsPosts.map(post => (
              <NewsItem post={post} expertName="NA" newsType="community" />
            ))}
        </div>
      </div>
    );
  }
}

export default CommunityNews;
