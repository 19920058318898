import React, { Component } from 'react';
import axios from 'axios';
import VodFrame from './vod/VodFrame';
import { apiUrl, vodUrl } from '../Config';

// will be used for single expert or all experts
class Vod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vodList: [],
      vodMode: 'all',
      //other is single, for one expert
      expertUserId: 0,
      isPopulated: false
    };
  }

  componentDidMount() {
    const vodMode = this.props.match.params.vodMode;
    const expertUserId = this.props.match.params.expertUserId;

    if (vodMode === 'all') {
      this.setState({ vodMode: 'all/', expertUserId: 0 });
    } else {
      this.setState({ vodMode: 'expert/', expertUserId: expertUserId });
    }

    this.populateVod();
  }

  async populateVod() {
    const vodMode = this.props.match.params.vodMode;
    const expertUserId = this.props.match.params.expertUserId;
    const vodApi = apiUrl + vodUrl + vodMode + '/' + expertUserId;
    const res = await axios.get(vodApi).catch(err => console.log(err));
    const vodList = res.data;
    this.setState({ vodList: vodList, isPopulated: true });
  }
  render() {
    const { isPopulated, vodList } = this.state;
    const vodMode = this.props.match.params.vodMode;

    //debugger;
    if (isPopulated === false) {
      return (
        <div style={{ color: 'green' }}>Please Wait, Populating VOD's...</div>
      );
    } else if (vodMode === 'all' && isPopulated === true) {
      return (
        <div>
          {/* <h4>All VOD's</h4> */}
          {vodList.map(expert => (
            <div>
              <h6 className="mt-3-mb-3">Expert: {expert.physician_name}</h6>
              <div>
                {expert.expertVods.map(
                  vod =>
                    vod.physicians_only_flg === 'N' && (
                      <VodFrame vod={vod} key={vod.vimeo_id} />
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (vodMode === 'expert' && isPopulated === true) {
      return (
        <div>
          {vodList.map(expert => (
            <div>
              <h6 className="mt-3 mb-3">VODs For: {expert.physician_name}</h6>
              <div>
                {expert.expertVods.map(
                  vod =>
                    vod.physicians_only_flg === 'N' && <VodFrame vod={vod} />
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return <div>VOD Page</div>;
    }
  }
}
export default Vod;
