import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo_to_fix from '../../assets/logo_to_fix.png';

class KaigieNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false
    };

    this.setIsNavExpanded = isNavExpanded => {
      this.setState({ isNavExpanded: isNavExpanded });
    };

    this.handleClick = e => {
      if (this.node.contains(e.target)) {
        // if clicked inside menu do something
      } else {
        // If clicked outside menu, close the navbar.
        this.setState({ isNavExpanded: false });
      }
    };
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  render() {
    var vWidth = window.innerWidth;
    return (
      <div ref={node => (this.node = node)}>
        <Navbar
          bg="light"
          expand="lg"
          fixed="top"
          collapseOnSelect
          onToggle={this.setIsNavExpanded}
          expanded={this.state.isNavExpanded}
          style={{ maxWidth: vWidth }}
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#/">Home</Nav.Link>
              <Nav.Link href="#/experts">Expert Listing</Nav.Link>
              <Nav.Link href="#/communitynews">Community News</Nav.Link>
              <Nav.Link href="#/vod/all/0">Video On Demand</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand href="#home">
            <img
              src={logo_to_fix}
              alt="kaigie logo"
              width="120"
              className="ml-6"
            />
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }
}

export default KaigieNavBar;
