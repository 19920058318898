import React, { Component } from 'react';
import './App.css';
import ExpertContainer from './components/ExpertContainer';
import Vod from './components/Vod';
import News from './components/News';
import Home from './components/Home';
import CommunityNews from './components/CommunityNews';
import ExpertDetail from './components/ExpertDetail';
import GalleryTest from './components/GalleryTest';
import Gallery2 from './components/Gallery2';
import VideoGallery from './components/VideoGallery';
import AddressToMapTest from './components/AddressToMapTest';
import { HashRouter, Route } from 'react-router-dom';
import './css/kaigienews.css';
import { Navbar, Nav } from 'react-bootstrap';
import KaigieNavBar from './components/common/KaigieNavBar';
import logo_to_fix from './assets/logo_to_fix.png';
export default class App extends Component {
  render() {
    return (
      <div>
        <section>
          <div>
            <KaigieNavBar />
            {/* <Navbar bg="light" expand="lg" fixed="top">
              <Navbar.Brand href="#home">
                <img
                  src={logo_to_fix}
                  alt="kaigie logo"
                  width="120"
                  className="ml-6"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="#/">Home</Nav.Link>
                  <Nav.Link href="#/experts">Expert Listing</Nav.Link>
                  <Nav.Link href="#/communitynews">Community News</Nav.Link>
                  <Nav.Link href="#/vod/all/0">Video On Demand</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar> */}
          </div>
        </section>

        <div className="container kaigie-container">
          <section />
          <HashRouter>
            <Route exact path="/experts" component={ExpertContainer} />
            <Route exact path="/" component={Home} />
            {/* expertUserId either all or int   */}
            <Route path="/vod/:vodMode/:expertUserId" component={Vod} />
            <Route
              path="/expertdetail/:expertUserId"
              component={ExpertDetail}
            />
            <Route exact path="/communitynews" component={CommunityNews} />
            <Route
              exact
              path="/news/:newsType/:expertUserId"
              component={News}
            />
            <Route path="/gallerytest" component={GalleryTest} />
            <Route path="/gallery2" component={Gallery2} />
            <Route path="/gallerycontainer" component={Gallery2} />
            <Route path="/videogallery" component={VideoGallery} />
            <Route path="/maptest" component={AddressToMapTest} />
            <Route path="/home" component={Home} />
          </HashRouter>
        </div>
      </div>
    );
  }
}
