import React, { Component } from 'react';
import NewsItem from './news/NewsItem';

import axios from 'axios';
import { apiUrl } from '../Config';
// will be used for single expert or all experts
class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsItems: [],
      newsType: 'expert',
      expert: [],
      expertArtistId: 0,
      newsPopulated: false
    };
  }
  componentDidMount() {
    const { newsType, expertUserId } = this.props.match.params;
    this.setState({ newsType: newsType });
    if (newsType === 'expert') {
      this.getExpert(expertUserId);
      this.getExpertPosts(expertUserId);
    }
  }

  async getExpert(expertUserId) {
    const expertUrl = apiUrl + '/fullphysician/' + expertUserId;

    const res = await axios.get(expertUrl).catch(err => console.log(err));
    this.setState({
      expertUserId: res.data[0].artist_id,
      expert: res.data[0],
      expertArtistId: res.data[0].artist_id
    });
  }

  async getExpertPosts(expertUserId) {
    const postsUrl = apiUrl + '/expertnewspost/' + expertUserId;

    const res = await axios.get(postsUrl).catch(err => console.log(err));
    console.log(res);
    this.setState({
      newsPosts: res.data,
      newsPopulated: true
    });
  }

  async getCommunityPosts(expertUserId) {
    const postsUrl = apiUrl + '/communitynewspost/';

    const res = await axios.get(postsUrl).catch(err => console.log(err));
    console.log(res);
    this.setState({
      newsPosts: res.data,
      newsPopulated: true
    });
  }
  createMarkup = description => {
    return { __html: description };
  };
  render() {
    const { expertUserId } = this.props.match.params;
    return (
      <div>
        <div className="row  justify-content-center mb-5">
          <h4 style={{ alignSelf: 'center', textAlign: 'center' }}>
            News Posts{' '}
            {this.state.newsType === 'expert'
              ? ` For  ${this.state.expert.physician_name} `
              : ''}{' '}
            <br />
            <small>
              <a href={`/#/expertdetail/${expertUserId}`}>
                About {this.state.expert.physician_name}
              </a>
            </small>
          </h4>
        </div>
        <div className="row justify-content-center">
          {this.state.newsPopulated === false && (
            <h5 style={{ color: 'green' }}>Please Wait - Getting News</h5>
          )}
          {this.state.newsPopulated === true &&
            this.state.newsType === 'expert' &&
            this.state.newsPosts.map(post => (
              <NewsItem post={post} expertName={post.physician_name} />
            ))}
        </div>
      </div>
    );
  }
}

export default News;
