import React, { Component } from 'react';
import ExpertList from './ExpertList';

import Select from 'react-select';
import axios from 'axios';
import { apiUrl } from '../Config';

class ExpertContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specialties: [],
      selectedOption: 'all',
      expertsPopulated: false
    };
  }
  // change specialties source to active primary specialties

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  componentWillMount() {
    // change sql to getting primary specialty for now
    const specialtiesUrl = apiUrl + '/specialty';
    axios
      .get(specialtiesUrl)
      .then(response => {
        console.log(response);

        this.setState({ specialties: response.data });
      })
      .catch(error => console.log(error.response));
  }
  render() {
    const { specialties, selectedOption } = this.state;
    return (
      <div className="mt-5">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <h5>Kaigie Experts</h5>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-5"
            style={{ fontSize: '.9em' }}
          >
            <Select
              placeholder="Choose Specialty"
              value={selectedOption}
              onChange={this.handleChange}
              options={specialties}
              inputProps={{ readOnly: true }}
              isSearchable={false}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'lightgray',
                  primary: 'black'
                }
              })}
              className="kaigie-select"
            />
          </div>
          <div className="hr" />
        </div>
        <div>
          <div className="card">
            {/* <div className="card-title h6">Experts List</div> */}
            <div className="hr" />
          </div>{' '}
          <div className="row">
            <ExpertList
              id="expertList"
              selectedSpecialty={this.state.selectedOption.value}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertContainer;
