import React, { Component } from 'react';
import { apiUrl, pageVideoUrl } from '../Config';
import axios from 'axios';
import VideoPlayer from './VideoPlayer';

class VideoGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: [],
      VideoType: 'P',
      videosPopulated: false
    };
  }

  async populateExpertVideos(expertUserId) {
    const videoUrl = apiUrl + pageVideoUrl + '/' + expertUserId;

    const res = await axios.get(videoUrl).catch(err => console.log(err));
    this.setState({ videos: res.data, videosPopulated: true });
  }
  componentWillMount() {}
  componentDidMount() {
    const { expertUserId } = this.props;
    this.populateExpertVideos(expertUserId);
    this.setState({ videosPopulated: true });
    // var { videos } = this.state;
    // console.log(videos);
  }

  render() {
    const { videosPopulated, videos } = this.state;
    return (
      <div>
        {/* <div className="row">
          <h3 style={{ alignSelf: 'center' }}>Videos</h3>
        </div> */}

        <div className="row">
          {videosPopulated === true &&
            videos.map(video => (
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div>
                  <VideoPlayer video={video} />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default VideoGallery;
