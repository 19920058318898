import React, { Component } from 'react';

class DetailsButtons extends Component {
  render() {
    const expertUserId = this.props.expertUserId;
    return (
      <section className="card border-light">
        <div className="button-group ml-2">
          <a
            href={`#/news/expert/${expertUserId}`}
            className="btn btn-sm btn-light"
          >
            News
          </a>
          <a
            href={`#/vod/expert/${expertUserId}`}
            className="btn btn-sm btn-light"
          >
            VOD
          </a>
        </div>
      </section>
    );
  }
}

export default DetailsButtons;
