import React, { Component } from 'react';
import axios from 'axios';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { apiUrl, mapKey } from '../Config';

class AddressToMapTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressData: [],
      formattedAddress: 'Unknown',
      location: [],
      addressPopulated: false,
      expertUserId: 0
    };
  }

  testAddress = '16 Harbor Ter, Apt 4J, Perth Amboy, NJ, 08861';
  isMarkerShown = true;
  incomingAddress = '';
  async getAddressData(expertUserId) {
    const addressUrl = apiUrl + '/physicianmapaddress/' + expertUserId;

    const addressRes = await axios
      .get(addressUrl)
      .catch(err => console.log(err));

    const address = addressRes.data[0];

    const mapQuery = address.mapQuery;

    const geolocateQuery =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      mapQuery +
      '&key=' +
      mapKey;

    const res = await axios.get(geolocateQuery).catch(err => console.log(err));
    console.log(res);
    this.setState({ addressData: res.data });

    const { addressData } = this.state;
    const addressInfo = addressData.results[0];
    console.log('Individual Address Info');
    console.log(addressInfo);
    const formattedAddress = addressInfo.formatted_address;
    const geometryLocation = addressInfo.geometry.location;
    console.log('Location: ');
    console.log(geometryLocation);

    this.setState({
      formattedAddress: formattedAddress,
      location: geometryLocation,
      addressPopulated: true,
      mapQuery: ''
    });
  }
  componentWillMount() {
    this.setState({ expertUserId: this.props.expertUserId });
  }
  componentDidMount() {
    this.getAddressData(this.state.expertUserId);
  }
  render() {
    const { location } = this.state;
    const GoogleMapExample = withGoogleMap(props => (
      <div>
        <GoogleMap
          defaultCenter={{ lat: location.lat, lng: location.lng }}
          defaultZoom={17}
        >
          <Marker position={{ lat: location.lat, lng: location.lng }} />
        </GoogleMap>
      </div>
    ));
    return (
      <div style={{ alignContent: 'center' }}>
        {/* <div style={{ selfAlign: 'center' }}>
          Location (lat, long) {this.state.location.lat},{' '}
          {this.state.location.lng}
        </div> */}
        <div style={{ selfAlign: 'center' }}>
          <GoogleMapExample
            containerElement={
              <div style={{ height: `325px`, width: '325px' }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    );
  }
}

export default AddressToMapTest;
