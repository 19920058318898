import React, { Component } from 'react';
import '../css/kaigiehcp2019.css';

export default class ExpertCard extends Component {
  constructor(props) {
    super(props);
    const whatAmI = 'expert';
  }
  render() {
    const { expert } = this.props;

    return (
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2 expert-info-grid">
        <div className="card expert-card border-light">
          <section>
            <div
              className="row mt-2 pt-2 pl-1 mr-2 expert-heading pb-2"
              style={{ height: 185, maxHeight: 185 }}
            >
              <div
                className="col-xs-12 col-sm-6 col-md-6 col-lg-4"
                style={{ height: '135px', overflow: 'hidden' }}
              >
                <div className="img-container">
                  <img
                    alt="expert"
                    className="img img-thumbnail img-responsive"
                    src={expert.primary_picture_url}
                  />
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-6 col-md-6 col-lg-8"
                style={{ height: '100px', overflow: 'hidden' }}
              >
                <h6 className="card-title kaigie-expert-name">
                  {expert.physician_name}
                </h6>
              </div>
            </div>
          </section>
          <div className="kaigie-expert-card-body">
            <div className="primary-specialty">
              Primary Specialty: {expert.specialty}
            </div>
            <div className="card-text" style={{ display: 'block' }}>
              {' '}
              <address className="expert-address">
                <div>
                  {' '}
                  {expert.office_address_1}{' '}
                  {expert.office_address_2 === null
                    ? ` `
                    : expert.office_address_2}{' '}
                </div>
                <div>
                  {expert.office_city},{expert.office_state}{' '}
                  {expert.office_postal_code}{' '}
                </div>

                <div title="Phone">Phone: {expert.office_phone_1}</div>

                <div title="Email">Email: {expert.office_email_1}</div>
              </address>
              <div className="hr" />
              <div className="fading-text mt-2">{expert.short_bio}</div>
            </div>
          </div>
          <section className="card expert-card border-light">
            <div className="button-group ml-2">
              <a
                href={`#/expertdetail/${expert.user_id}`}
                className="btn btn-sm btn-light"
              >
                More
              </a>
              <a
                href={`#/news/expert/${expert.user_id}`}
                className="btn btn-sm btn-light"
              >
                News
              </a>
              <a
                href={`#/vod/expert/${expert.user_id}`}
                className="btn btn-sm btn-light"
              >
                VOD
              </a>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
