import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasVideos: true
    };
    this.videoWidth = 320;
    this.VideoHeight = 180;
    this.videoWidthInt = 320;
    this.VideoHeightInt = 180;
  }
  componentWillRender() {
    const videoCount = this.props.artist_id;
    if (videoCount === 0) {
      this.setState({ hasVideos: false });
    }
  }

  render() {
    const { video } = this.props;
    var windowWidth = parseInt(window.innerWidth);

    if (windowWidth <= 480) {
      this.videoWidth = '240px';
      this.videoHeight = '135px';
      this.videoWidthInt = 240;
      this.VideoHeightInt = 135;
    }

    return (
      <div style={{ width: '100%' }}>
        {video.artist_id > 0 ? (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xlg-6">
              <ReactPlayer
                className="img img-thumbnail"
                style={{
                  margin: '10px',
                  borderColor: 'lightgray',
                  borderWidth: '2px'
                }}
                url={video.media_url}
                width={320}
                height={180}
                light={false}
                preloading={true}
                config={{
                  vimeo: {
                    playerOptions: {
                      maxwidth: this.videoWidthInt,
                      maxheight: this.VideoWidthHeight
                    }
                  }
                }}
              />
            </div>
            {/* <div className="d-block d-md-none" /> */}
            <div
              className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xlg-6"
              style={{ fontSize: '.8em' }}
            >
              <div>{video.media_title}</div>
            </div>
          </div>
        ) : (
          <div>
            <h5 style={{ alignSelf: 'center' }}>No Videos</h5>
          </div>
        )}
      </div>
    );
  }
}
export default VideoPlayer;
