import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'moment';
import { apiUrl } from '../../Config';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      commentsPopulated: false
    };
  }
  componentDidMount() {
    this.populateComments(this.props.userPostingThreadId);
  }

  async populateComments(userPostingThreadId) {
    const commentUrl = apiUrl + '/newspostcomment/' + userPostingThreadId;
    const res = await axios.get(commentUrl).catch(err => console.log(err));
    console.log(res);
    this.setState({ comments: res.data, commentsPopulated: true });
  }

  createMarkup = description => {
    return { __html: description };
  };
  render() {
    return (
      <div className="ml-1 pl-3 mb-5">
        <div style={{ fontSize: '.9em' }}>Comments</div>
        <div
          className="ml-2"
          style={{ backgroundColor: '#f7f7f7', width: '80%%' }}
        >
          <div className="row">
            {this.state.commentsPopulated === true &&
              this.state.comments.map(comment => (
                <div className="row ml-1 pl-1" style={{ width: '90%' }}>
                  <div
                    style={{ fontSize: '.8em', width: '90%' }}
                    dangerouslySetInnerHTML={this.createMarkup(comment.comment)}
                  />
                  <div style={{ fontSize: '.7em', width: '90%' }}>
                    {comment.user_id > 0 &&
                      `Comment Posted By: ${comment.UserName} at ${Moment(
                        comment.posted_datetime
                      ).format('MMMM Do YYYY, h:mm a ')}`}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Comments;
