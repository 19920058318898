import React from 'react';
import axios from 'axios';
import { apiUrl } from '../Config';
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';

// const images = [{ src: 'path/to/image-1.jpg' }, { src: 'path/to/image-2.jpg' }];

class GalleryTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: 'expert gallery',
      images: [],
      expertUserId: 8,
      modalIsOpen: false,
      isPopulated: false
    };

    this.images = [];
  }
  componentWillMount() {
    // const expertUserId = this.props.expertUserId;
    // this.setState({ expertUserId: expertUserId });

    //this.populateGallery(this.props.expertUserId);
    this.populateGallery(8);
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };

  async populateGallery(expertUserId) {
    //  debugger;
    // const { expertUserId } = this.props;
    console.log('expertUserId Param: ' + expertUserId);
    const picUrl = apiUrl + '/expertimage/' + expertUserId;
    const res = await axios.get(picUrl).catch(err => console.log(err));
    this.setState({ images: res.data, isPopulated: true });
  }
  render() {
    const { images, isPopulated } = this.state;

    return (
      <div>
        {isPopulated === true && (
          <Gallery images={this.state.images} rowHeight={120} />
        )}
        Hello
      </div>
    );
  }
}

export default GalleryTest;
