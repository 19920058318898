import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class VodFrame extends Component {
  constructor(props) {
    super(props);
    this.videoWidth = 320;
    this.VideoHeight = 180;
    this.videoWidthInt = 320;
    this.VideoHeightInt = 180;
  }
  render() {
    const vod = this.props.vod;
    var windowWidth = parseInt(window.innerWidth);

    if (windowWidth <= 480) {
      this.videoWidth = 240;
      this.videoHeight = 135;
      this.videoWidthInt = 240;
      this.VideoHeightInt = 135;
    }
    return (
      <div>
        <div className="row mb-5">
          <div
            className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
            style={{ display: 'inline' }}
          >
            <ReactPlayer
              className="img img-thumbnail"
              style={{
                margin: '10px',
                borderColor: 'lightgray',
                borderWidth: '2px'
              }}
              url={vod.vimeo_url}
              width={320}
              height={180}
              light={false}
              preloading={true}
              config={{
                vimeo: {
                  playerOptions: {
                    maxwidth: this.videoWidthInt,
                    maxheight: this.VideoWidthHeight
                  }
                }
              }}
            />
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
            style={{ display: 'inline', fontSize: '.8em' }}
          >
            {' '}
            <div style={{ width: '96%', marginLeft: '2%', marginRight: '2%' }}>
              {' '}
              {vod.vod_abstract}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VodFrame;
