import React, { Component } from 'react';

export default class NewsImage extends Component {
  render() {
    return (
      <div className="justify-content-center">
        <div style={{ width: '240px' }}>
          <img
            src={this.props.imgUrl}
            alt=""
            className="img img-thumbnail img-responsive"
          />
        </div>
      </div>
    );
  }
}
