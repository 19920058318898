import React, { Component } from 'react';
import KaigieHome from './assets/KaigieHome.png';
export default class Home extends Component {
  render() {
    return (
      <div className="row">
        <div className="container">
          <img
            className="img img-responsive img-thumbnail"
            src={KaigieHome}
            alt="kaigie home"
          />
          {/* ha ha */}
        </div>
      </div>
    );
  }
}
