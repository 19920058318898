import React, { Component } from 'react';
import Moment from 'moment';
import NewsImage from './NewsImage';
import NewsVideo from './NewsVideo';
import Comments from './Comments';

class NewsItem extends Component {
  createMarkup = description => {
    return { __html: description };
  };

  render() {
    const post = this.props.post;
    var itemMaxWidth = parseInt(window.width * 0.9) + 'px';
    const vimeoUrl =
      post.vimeo_id !== 'NA'
        ? 'https://player.vimeo.com/video/' + post.vimeo_id
        : 'NA';
    const physician_only_flg = post.physician_only_flg;
    return (
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-4 mb-4 rounded"
        style={{
          width: '100%',
          borderBottomColor: '#e8e9eb',
          borderBottomStyle: 'solid',
          borderBottomWidth: '2px',
          borderTopColor: '#e8e9eb',
          borderTopStyle: 'solid',
          borderTopWidth: '2px',
          alignSelf: 'center',
          maxWidth: itemMaxWidth
        }}
      >
        <div
          className="card-title pb-3"
          style={{
            backgroundColor: '#fff',
            borderBottomColor: '#cbcdd1',
            borderBottomStyle: 'solid',
            borderBottomWidth: '2px'
          }}
        >
          <h6 className="item-title">{post.artist_post_title}</h6>
          {this.props.newsType === 'community' && (
            <div className="ml-1">By {post.posting_user_screen_name}</div>
          )}
          <div className="ml-1" style={{ fontSize: '.75em' }}>
            {`Posted: ${Moment(post.posted_datetime).format(
              'MMMM Do YYYY, h:mm a '
            )}`}
          </div>
        </div>
        <div className="kaigie-card-body" style={{ fontSize: '.9em' }}>
          <div
            className="card-text"
            dangerouslySetInnerHTML={this.createMarkup(post.comment)}
          />
          <div>
            {post.artist_pic_flg === 'Y' && (
              <div>
                {' '}
                <div>Has Pic</div>
                <NewsImage imgUrl={post.artist_pic_url} />{' '}
              </div>
            )}
          </div>
          <div>
            {vimeoUrl !== 'NA' && physician_only_flg === 'N' && (
              <div>
                <div>Has Video</div>
                <NewsVideo vimeoUrl={vimeoUrl} />
              </div>
            )}
          </div>
          <div>
            {post.artist_doc_flg === 'Y' && <div>Has Doc Download</div>}
          </div>
        </div>
        <Comments userPostingThreadId={post.user_posting_thread_id} />
      </div>
    );
  }
}

export default NewsItem;
