import React, { Component } from 'react';
import ReactPlayer from 'react-player';

export default class NewsVideo extends Component {
  constructor(props) {
    super(props);
    this.videoWidth = 320;
    this.VideoHeight = 180;
    this.videoWidthInt = 320;
    this.VideoHeightInt = 180;
  }
  componentDidMount() {
    console.log('Vimeo Url ' + this.props.vimeoUrl);
  }

  render() {
    var windowWidth = parseInt(window.innerWidth);

    if (windowWidth <= 480) {
      this.videoWidth = 240;
      this.videoHeight = 135;
      this.videoWidthInt = 240;
      this.VideoHeightInt = 135;
    }

    return (
      <div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xlg-6">
          <ReactPlayer
            url={this.props.vimeoUrl}
            width={320}
            height={180}
            light={false}
            preloading={true}
            config={{
              vimeo: {
                playerOptions: {
                  maxwidth: this.videoWidthInt,
                  maxheight: this.VideoWidthHeight
                }
              }
            }}
          />
        </div>
      </div>
    );
  }
}
