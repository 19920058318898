import React from 'react';
import axios from 'axios';
import { apiUrl } from '../Config';
// import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';

// const images = [{ src: 'path/to/image-1.jpg' }, { src: 'path/to/image-2.jpg' }];

class Gallery2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: 'expert gallery',
      images: [],
      expertUserId: 8,
      modalIsOpen: false,
      isPopulated: false
    };

    this.images = [];
  }
  styleSmall() {
    return {
      // padding: '2px',
      width: 160,
      height: 120
    };
  }

  componentWillMount() {
    const expertUserId = this.props.expertUserId;
    // this.setState({ expertUserId: expertUserId });

    //this.populateGallery(this.props.expertUserId);
    this.populateGallery(expertUserId);
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };

  async populateGallery(expertUserId) {
    //  debugger;
    // const { expertUserId } = this.props;
    console.log('expertUserId Param: ' + expertUserId);
    const picUrl = apiUrl + '/expertimage/' + expertUserId;
    const res = await axios.get(picUrl).catch(err => console.log(err));
    this.setState({ images: res.data, isPopulated: true });
  }
  render() {
    const { images, isPopulated } = this.state;

    return (
      <div>
        {isPopulated === true && images[0].artist_id > 0 ? (
          <Gallery
            images={images}
            rowHeight={120}
            enableLightbox={true}
            margin={4}
            showLightboxThumbnails={true}
            tileViewportStyle={this.styleSmall}
          />
        ) : (
          <div>
            <h5 style={{ alignSelf: 'center' }}>No Pictures</h5>
          </div>
        )}
      </div>
    );
  }
}
export default Gallery2;
