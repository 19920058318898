import React, { Component } from 'react';
import ExpertCard from './ExpertCard';
import axios from 'axios';
import { apiUrl } from '../Config';

export default class ExpertList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialty: 'all',
      selectedSpecialty: 'all',
      experts: [],
      filteredExperts: [],
      expertsPopulated: false
    };
  }

  async getExperts() {
    // will be all
    var physiciansURL = apiUrl + '/physician2019/' + this.state.specialty;

    const res = await axios.get(physiciansURL).catch(err => console.log(err));
    console.log(res);

    this.setState({
      experts: res.data
    });

    var initExpertList = this.state.experts.filter(item => {
      return item.filter_specialty === 'all';
    });
    this.setState({
      filteredExperts: initExpertList
    });
    //     //start
    //   .then(res => {
    //     console.log(res);

    //     this.setState({
    //       experts: res.data
    //     });

    //     var initExpertList = this.state.experts.filter(item => {
    //       return item.filter_specialty === 'all';
    //     });
    //     this.setState({
    //       filteredExperts: initExpertList
    //     });
    //   });
    // //end
  }

  async getExpertsPrimary() {
    var physiciansURL =
      apiUrl + '/physician2019/' + this.state.specialty + '/primary';

    await axios
      .get(physiciansURL)
      .catch(console.log('error getting expert data'))
      .then(res => {
        console.log(res);
        this.setState({ experts: res.data, filteredExperts: res.data });
      });
  }

  renderExperts() {
    const { experts } = this.state;
    this.setState({ refreshed: true });
    return experts.map(expert => (
      <ExpertCard key={expert.user_id} expert={expert} />
    ));
  }

  componentWillReceiveProps({ selectedSpecialty }) {
    this.setState({ ...this.state, selectedSpecialty });
    this.setState({ specialty: selectedSpecialty });

    var filteredExperts;
    filteredExperts = this.state.experts.filter(
      expert => expert.filter_specialty === selectedSpecialty
    );

    this.setState({
      filteredExperts: filteredExperts,
      expertsPopulated: true
    });
  }
  componentWillMount() {}

  componentDidMount() {
    this.getExperts();
  }

  render() {
    const { filteredExperts, expertsPopulated } = this.state;
    if (expertsPopulated === true) {
      return filteredExperts.map(expert => (
        <ExpertCard key={expert.user_id} expert={expert} />
      ));
    } else {
      return (
        <div className="ml-5" style={{ alignSelf: 'center', color: 'green' }}>
          Please wait...
        </div>
      );
    }
  }
}
