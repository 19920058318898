import React, { Component } from 'react';
import axios from 'axios';
import { apiUrl } from '../Config';
import Gallery2 from './Gallery2';
import VideoGallery from './VideoGallery';
import AddressToMapTest from './AddressToMapTest';
import DetailsButtons from './DetailsButtons';

//import '../js/jquery.fancybox.js';

class ExpertDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expert: [],
      expertUserId: 0,
      expertArtistId: 0,
      images: []
    };
  }

  componentDidMount() {
    const expertUserId = this.props.match.params.expertUserId;

    this.populateExpert(expertUserId);
  }

  async populateExpert(expertUserId) {
    const expertUrl = apiUrl + '/fullphysician/' + expertUserId;

    const res = await axios.get(expertUrl).catch(err => console.log(err));
    this.setState({
      expertUserId: expertUserId,
      expert: res.data[0],
      expertArtistId: res.data[0].artist_id
    });
  }
  createMarkup = description => {
    return { __html: description };
  };

  render() {
    const { expert } = this.state;
    const comma = ', ';
    const {
      office_address_1,
      office_address_2,
      office_city,
      office_state,
      office_postal_code
    } = expert;

    var fullAddress =
      office_address_1 + comma + office_city + comma + office_state;
    fullAddress.replace(/ /g, '+');
    console.log('Full Address String Sent To Map');
    console.log(fullAddress);
    const expertUserId = this.props.match.params.expertUserId;
    return (
      <div className="expert-detail-container">
        <div className="mt-5">
          {/* title */}
          <section className="mb-4">
            <h4>{expert.physician_name}</h4>
          </section>
          <section>
            {/* image */}
            <div>
              <div id="imgSpan" style={{ width: 80 }}>
                <img
                  className="img img-thumbnail img-responsive"
                  src={expert.thumbnail_url}
                  alt="expert"
                />
              </div>
            </div>
            <hr />
            <DetailsButtons expertUserId={expert.user_id} />
            <div className="expert-detail-card">
              <div className="kaigie-expert-card-body">
                <div
                  className="card-text"
                  dangerouslySetInnerHTML={this.createMarkup(expert.long_bio)}
                />
              </div>
            </div>
          </section>
          <DetailsButtons expertUserId={expert.user_id} />
          <section>
            <div className="row mb-3">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="expert-detail-card">
                  <div className="kaigie-expert-card-body">
                    <div>{expert.physician_name}</div>
                    <div>{office_address_1}</div>
                    <div>{office_address_2}</div>
                    <div>{office_city}</div>
                    <div>{office_state}</div>
                    <div>{office_postal_code}</div>
                    <div>{expert.office_phone_1}</div>
                    <div>{expert.office_email_1}</div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12  col-md-12 col-lg-6">
                <AddressToMapTest expertUserId={expertUserId} />
              </div>
            </div>
          </section>
          {/* <section>
            <h6>Picture Gallery</h6>
            <ExpertGallery expertUserId={expertUserId} />
          </section> */}
          <section>
            <div className="expert-detail-card" style={{ width: '100%' }}>
              <div className="card-title" style={{ alignSelf: 'center' }}>
                Expert Gallery
              </div>
              <div className="kaigie-expoert-card-body">
                <Gallery2 expertUserId={expertUserId} />
              </div>

              {/* <Gallery2 expertUserId={expertUserId} /> */}
              <br />
            </div>
          </section>
          <section>
            <div className="expert-detail-card" style={{ width: '100%' }}>
              <div className="card-title" style={{ alignSelf: 'center' }}>
                Videos
              </div>
              <div className="kaigie-expert-card-body">
                <VideoGallery expertUserId={expertUserId} />
              </div>

              <br />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ExpertDetail;
